<template>
  <v-dialog v-model="open" width="700" max-width="100%" persistent scrollable>
    <v-card
      class="mx-auto"
      :class="{
        'w-vinci-form-modal': true,
        'w-vinci-form-modal--mobile': $voicer.isMobile,
      }"
    >
      <v-toolbar flat class="color: #fbde51">
        <v-spacer />
        <v-btn icon small @click.stop="open = false">
          <v-icon color="primary">mdi-close-thick</v-icon>
        </v-btn>
      </v-toolbar>
      <v-img
        :src="
          $voicer.isMobile
            ? require('~/assets/images/Popupmobile.png')
            : require('~/assets/images/Popupdesktop.png')
        "
        alt="image popup"
        :max-height="$voicer.isMobile ? 150 : 250"
        @click="handleClick"
      />
      <strong
        ><div class="text-alternate pa-1 pt-3">
          Les bonnes ondes, c'est terminé pour cette saison
        </div></strong
      >
      <div class="text-alternate pl-2 pr-2">
        Merci encore pour votre écoute et fidélité. N'hésitez pas à nous dire ce
        que vous avez aimé dans ce programme, ce que vous aimeriez y (re)trouver
        en nous écrivant à
        <a href="mailto:contact.lesbonnesondes@tdf.fr"
          >contact.lesbonnesondes@tdf.fr</a
        >
        <br />
        Tous ceux qui y ont travaillé ne rêvent que d'une chose : vous retrouver
        en 2025 pour vous proposer une nouvelle saison, encore plus tournée vers
        vous et pour continuer à diffuser de bonnes ondes ! <br />A bientôt.
      </div>
      <div class="text-alternate pa-1">
        <v-btn
          ref="btn"
          color="primary"
          depressed
          class="ma-2 white--text"
          href="mailto:contact.lesbonnesondes@tdf.fr"
        >
          <v-icon color="#ffffff" class="mr-2">mdi-send-circle-outline</v-icon>
          Nous écrire
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
import localstore from 'store'
export default {
  data() {
    const lastOpen = localstore.get('exc_dialog_last_open') || null
    let open = false
    if (!lastOpen) open = true
    else {
      const now = new Date()
      const lastOpenDate = new Date(lastOpen)
      lastOpenDate.setDate(lastOpenDate.getDate() + 1)
      if (lastOpenDate < now) {
        open = true
      }
    }
    if (open === true) {
      localstore.set('exc_dialog_last_open', new Date())
      this.$store.dispatch('player/closePersistantPlayer')
    }
    return {
      open,
    }
  },
  watch: {
    open(status) {
      if (status === false) {
        this.$store.dispatch('player/openPersistantPlayer')
      }
    },
  },
  methods: {
    handleClick() {
      this.open = false
      this.$emit('open-form')
    },
  },
}
</script>
<style lang="scss">
.headline {
  font-size: 15px;
}
.w-vinci-form-modal {
  &--mobile {
    display: flex;
    flex-direction: column;
    .v-card__text {
      flex-grow: 1;
      position: relative;
    }
  }
  &__body2 {
    justify-content: center;
    text-align: center;
    align-items: center;
  }
  .body-2 {
    justify-content: center;
    text-align: center;
    align-items: center;
    display: inherit;
  }
  .body-1 {
    justify-content: center;
    text-align: center;
    align-items: center;
    display: inherit;
    font-family: Lato;
  }
  .text-alternate {
    color: #111212;
    text-align: center;
  }
  /* desktops */
  @media (min-width: 992px) {
    .text-alternate {
      font-size: 16px;
    }
  }

  /* mobiles */
  @media (max-width: 768px) {
    .text-alternate {
      font-size: 12px;
    }
  }
}
</style>
