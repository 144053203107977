<template>
  <div class="w-block__footer">
    <!-- footer block -->
    <img
      :src="require('~/assets/images/logotdf.png')"
      height="80"
      alt="tdf"
      class=""
    />
    <exc-dialog />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import ExcDialog from '~/excentrics/components/ExcDialog'
export default {
  components: {
    ExcDialog,
  },
  computed: {
    ...mapGetters({
      isPersistantVisible: 'player/isPersistantVisible',
    }),
  },
  methods: {
    handleOpen(url) {
      window.open(url, '_blank')
    },
  },
}
</script>
