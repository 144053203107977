<template>
  <div class="w-block__pre-login">
    <!-- pre-login block -->
    <strong
      ><div class="body2 pa-1 pt-3">
        Les bonnes ondes, c'est terminé pour cette saison
      </div></strong
    >
    <div class="body2 pl-2 pr-2">
      Merci encore pour votre écoute et fidélité. N'hésitez pas à nous dire ce
      que vous avez aimé dans ce programme, ce que vous aimeriez y (re)trouver
      en nous écrivant à
      <a href="mailto:contact.lesbonnesondes@tdf.fr"
        >contact.lesbonnesondes@tdf.fr</a
      >
      <br />
      Tous ceux qui y ont travaillé ne rêvent que d'une chose : vous retrouver
      en 2025 pour vous proposer une nouvelle saison, encore plus tournée vers
      vous et pour continuer à diffuser de bonnes ondes ! <br />A bientôt.
    </div>
    <div class="body2 pa-1">
      <v-btn
        ref="btn"
        color="primary"
        depressed
        class="ma-2 white--text"
        href="mailto:contact.lesbonnesondes@tdf.fr"
      >
        <v-icon color="#ffffff" class="mr-2">mdi-send-circle-outline</v-icon>
        Nous écrire
      </v-btn>
    </div>
  </div>
</template>
<script>
export default {
  watch: {
    open(status) {
      if (status === false) {
        this.$store.dispatch('player/openPersistantPlayer')
      }
    },
  },
  methods: {
    handleClick() {
      this.open = false
      this.$emit('open-form')
    },
  },
}
</script>
<style lang="scss">
.body2 {
  color: #111212;
  text-align: center;
  justify-content: center;
}
/* desktops */
@media (min-width: 992px) {
  .body2 {
    font-size: 16px;
  }
}

/* mobiles */
@media (max-width: 768px) {
  .body2 {
    font-size: 12px;
  }
}
</style>
