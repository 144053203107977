/**
 * configuration applied when BROCOLI_API_MODE = staging
 */
module.exports = {
  authMode: ['providers'],
  authOAuthProviders: [
    [
      'WSSOKeycloak',
      {
        realm: 'TDF-preprod',
        clientId: 'vi-tdf-staging',
        buttonText: 'Connexion TDF',
      },
    ],
  ],
  useShell: [
    'bb-default',
    {
      mode: 'universes',
      universes: [
        {
          type: 'tag',
          tagId: '65dda8543f03d7056fb52506',
          i18n: {
            fr: {
              title: `Épisodes`,
              description: `Vos épisodes complets directement disponibles en replay`,
            },
          },
          heading: {
            color: '#FF4B36',
            canRadio: false,
            dark: true,
            buttonColor: 'white',
            buttonLight: true,
          },
        },
        {
          type: 'tag',
          tagId: '65dda8643f03d7fbd4b5250c',
          i18n: {
            fr: {
              title: `Le petit mag`,
              description: `Vos interviews du petit mag disponibles en replay`,
            },
          },
          heading: {
            color: '#FFAA4A',
            canRadio: false,
            dark: true,
            buttonColor: 'white',
            buttonLight: true,
          },
        },
        {
          type: 'tag',
          tagId: '65dda8713f03d7218cb52512',
          i18n: {
            fr: {
              title: `La pause café`,
              description: `Vos interviews de la pause café directement disponibles en replay`,
            },
          },
          heading: {
            color: '#FF4B36',
            canRadio: false,
            dark: true,
            buttonColor: 'white',
            buttonLight: true,
          },
          filters: {
            type: 'solo',
            soloTagId: '65ddaa013f03d78719b5252d',
          },
        },
      ],
    },
  ],
}
